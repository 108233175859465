<template>
  <nutrition-template
    :processed-food-intake="processedFoodIntake"
    :anti-oxidant-intake="antiOxidantIntake"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import NutritionTemplate from '@/modules/questionnaire/components/steps/questionnaire/oxidative-stress/nutrition/NutritionTemplate';

import { makeStep } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

const { requiredField } = fieldBuilder;

const FIELDS = [requiredField('processedFoodIntake'), requiredField('antiOxidantIntake')];

export default {
  name: 'Nutrition',
  components: {
    NutritionTemplate
  },
  mixins: [makeStep(FIELDS)],
  computed: {},
  watch: {
    processedFoodIntake(newValue, oldValue) {
      if (!oldValue && this.antiOxidantIntake) {
        this.showNextStep();
      } else {
        this.scrollTo('#anti-oxidant-intake');
      }
    },
    antiOxidantIntake(newValue, oldValue) {
      if (!oldValue && this.processedFoodIntake) {
        this.showNextStep();
      }
    }
  }
};
</script>
